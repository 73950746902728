import { combineReducers } from 'redux'
import { config } from './config';
import { pages } from './pages';
import { events } from './events';
import { application } from './application';
import { authentication } from './authentication';

const rootReducer = combineReducers({
    config,
    pages,
    events,
    application,
    authentication
})

export default rootReducer