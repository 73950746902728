import React, { Component, useState, useEffect } from 'react'
import styles from './Map.module.sass'
import { withRouter, useLocation, useHistory, NavLink } from 'react-router-dom'
import moment from 'moment'

const hexToRgb = hex =>
    hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
             ,(m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1).match(/.{2}/g)
    .map(x => parseInt(x, 16))

function Detail({ active, onClose, history, colorSecondary, data, language }) {
    const [visible, setVisible] = useState(false)
    const [event, setEvent] = useState(data)

    useEffect(() => {
        if (active && !visible) {
            setVisible(true)

            const dates = data.dates.reduce((group, date) => {

                let current = moment(date, 'DD-MM-YYYY')
    
                if(!group.length) {
                    group.push({
                        start: date,
                        end: date
                    })
                } else {
    
                    let last = group[group.length-1]
    
                    if ( moment(current).add(-1, 'days').isSame( moment(last.end, 'DD-MM-YYYY')) || moment(current).isSame( moment(last.end, 'DD-MM-YYYY')) ) {
                        last.end = date
                    } else {
                        group.push({
                            start: date,
                            end: date
                        })
                    }
    
                }
    
                return group;
    
            }, [])

            setEvent({...data, dates})
        }

        if (!active && visible) {
            setTimeout(() => {
                setVisible(false)

            }, 500);
        }
    }, [active, visible])

    if (!visible) return null


    const backgroundImage = `url(${event.image}), linear-gradient(${colorSecondary}, #0022bf)`

    //console.log('render overlay')
    //console.log(document.fullscreen)

    let eventDates = event.dates.map((group, index) => {
        
        let date

        if(group.start === group.end) {
            date = moment(group.start, 'DD-MM-YYYY').format('DD.MM.YY')
        } else {
            date = moment(group.start, 'DD-MM-YYYY').format('DD.MM.') + '–' + moment(group.end, 'DD-MM-YYYY').format('DD.MM.YY')
        }

        return <p className="date" dangerouslySetInnerHTML={{__html: date}} />
        
    })

    return (
        <div className={styles.detail + (active ? ' in' : ' out')} onClick={() => onClose()}>
           <div>
                <div>
                    <div className="image" style={{backgroundImage: backgroundImage}}>
                        <span style={{backgroundImage: `linear-gradient(rgba(${hexToRgb(colorSecondary)},0), #0022bf`}} />
                    </div>
                    <div className="text">
                        <h2 dangerouslySetInnerHTML={{__html: event.title}}></h2>
                        {eventDates}
                        <span>{event.location}<br />{event.place}</span>
                    </div>
                    <NavLink to={event.path} />
                </div>
                <span className="close colorSecondaryHover" onClick={() => onClose()}>{language === 'de' ? 'ausblenden' : 'masquer'}</span>
           </div>
        </div>
    )
}

export default withRouter(Detail);