const costsLabels = [
  {
    title:
      'Künstlerische Personalkosten <span class="fr">Frais personnels artistiques</span>',
    placeHolders: [
      {
        de: "Künstler:innengagen (aufschlüsseln € pro Künstler:in)",
        fr: "Salaires/Honoraires/Cachets des artistes (indiquer € par artiste)",
      },
    ],
  },
  {
    title:
      'Reise- und Übernachtungskosten <span class="fr">Frais de déplacement et d\'hébergement</span>',
    placeHolders: [
      {
        de: "Reisekosten international (x Personen * xxx €)",
        fr: "Voyages internationaux (x Personnes * xxx €) ",
      },
      {
        de: "Reisekosten national",
        fr: "Voyages nationaux",
      },
      {
        de: "Tagegelder",
        fr: "Per diem",
      },
      {
        de: "Übernachtungskosten (x Personen * x € * Anzahl Übernachtungen)",
        fr: "Hébergement (x personnes * x € * nombre de nuitées)",
      },
    ],
  },
  {
    title: 'Produktionskosten <span class="fr">Frais de production</span>',
    placeHolders: [
      {
        de: "Transportkosten",
        fr: "transport de matériel",
      },
      {
        de: "Miete Proben-/Veranstaltungsort",
        fr: "Location de lieux",
      },
      {
        de: "Technikkosten (Miete, Anschaffungen)",
        fr: "Frais techniques matériels",
      },
      {
        de: "Technikkosten (Personal)",
        fr: "Frais techniques personnel associé",
      },
      {
        de: "Weitere Technikkosten",
        fr: "Autres frais techniques",
      },
    ],
  },
  {
    title:
      'Werbung und Öffentlichkeitsarbeit <span class="fr">Frais de promotion et de communication</span>',
    placeHolders: [
      {
        de: "Graphik- und Druckkosten (aufschlüsseln)",
        fr: "Frais de graphisme et d’édition (préciser)",
      },
      {
        de: "Webseite",
        fr: "Site internet",
      },
    ],
  },
  {
    title:
      'Verwaltungskosten (dürfen nicht höher als 20% des Budgets sein) <span class="fr">Frais administratifs (ils ne doivent pas excéder 20% du budget)</span>',
  },
  {
    title: 'Sonstige Kosten <span class="fr">Autres dépenses</span>',
    placeHolders: [
      {
        de: "Versicherungen",
        fr: "Assurances",
      },
      {
        de: "Visa",
        fr: "Frais de visa",
      },
      {
        de: "Genehmigungen",
        fr: "Autorisations",
      },
      {
        de: "SACEM, GEMA, SUISA",
        fr: "SACEM, GEMA, SUISA",
      },
    ],
  },
];

const revenueLabels = [
  {
    title: 'Erwartete Einnahmen <span class="fr">Recettes</span>',
    placeHolders: [
      {
        de: "Eintrittseinnahmen (aufschlüsseln)",
        fr: "Billetterie (préciser)",
      },
      {
        de: "Verkauf von Programmheften",
        fr: "Vente de programmes etc.",
      },
    ],
  },
  {
    title: 'Eigenmittel <span class="fr">Fonds propres</span>',
  },
  {
    title: 'Gesicherte Drittmittel (öffentlich und/oder privat) <span class="fr">Soutiens confirmés (publics et/ou privés)</span>',
  },
  {
    title: 'Geplante Drittmittel (öffentlich und/oder privat) <span class="fr">Soutiens prévus (publics et/ou privés)</span>',
  }
];

const benefitsLabels = [
    {
        title: 'Geldwerte Leistungen (aufschlüsseln) <span class="fr">Apports en industrie (préciser)</span>',
    }
]

export { costsLabels, revenueLabels, benefitsLabels };
