import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { withRouter, Link, NavLink } from 'react-router-dom'
import styles from './Navigation.module.sass'

import { getConfig } from 'actions'

import Logo from './Logo'

const Group = withRouter(({ location, data, colorSecondary, index, groupOpen, onClick }) => {
    const [height, setHeight] = useState(0)
    const ref = useRef(null)
    //console.log(data)
    const pathMain = data.children.length ? data.children[0].path : data.path
    const activeStyle = { color: colorSecondary }

    const isActive = (path) => (
        location.pathname.indexOf(path) !== -1
    )
    /*
    useEffect(() => {
        if (data.children.length) {
            setHeight(
                isActive(data.path) ? ref.current.clientHeight : 0
            )
        }
    }, [ref, location])
    */

    const handleClick = (event) => {

        if (data.children.length) {
            event.preventDefault()
            /*
            setHeight(
                height === 0 ? ref.current.clientHeight : 0
            )
            */
            onClick(index !== groupOpen ? index : null)
        }
    }

    console.log(index, index === groupOpen)

    return (
        <li>
            {
                !data.target ? (
                    <NavLink
                        to={pathMain}
                        isActive={() => isActive(data.path) || (index === groupOpen && onClick)}
                        activeStyle={activeStyle}
                        className="colorSecondaryHover"
                        onClick={handleClick}>
                        {data.title}
                    </NavLink>
                ) : (
                    <a href={data.path} target="_blank" className="colorSecondaryHover">{data.title}</a>
                )
            }

            {(data.children.length > 0) &&
                <div style={{ height: index === groupOpen ? `${ref.current.clientHeight}px` : 0 }}>
                    <ul ref={ref}>
                        {data.children.map((entry, i) => (
                            <li key={i}>
                                <NavLink
                                    to={entry.path}
                                    isActive={() => isActive(entry.path)}
                                    activeStyle={activeStyle}
                                    className="colorSecondaryHover"
                                    dangerouslySetInnerHTML={{ __html: entry.title }}>

                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </div>
            }
        </li>
    )
})

const Button = ({ data, colorSecondary }) => {
    if (!data.target) {
        return <Group
            colorSecondary={colorSecondary}
            data={data}
        />
    } else {
        return <li><a href={data.path} target="_blank" className="colorSecondaryHover">{data.title}</a></li>
    }
}

const Navigation = ({ navigation, getConfig, colorSecondary, location, language, loading, translation }) => {
    const [open, setOpen] = useState(false)
    const [animateLoading, setAnimateLoading] = useState(false);
    const [isHome, setIsHome] = useState(location.pathname !== '/' && location.pathname !== '/fr' ? false : true)
    const [groupOpen, setGroupOpen] = useState(null)

    const animateLoadingStart = useRef(null)
    const ref = useRef(null)

    useEffect(() => {
        getConfig()
    }, [])

    useEffect(() => {
        setIsHome(location.pathname !== '/' && location.pathname !== '/fr' ? false : true)

        setGroupOpen(null)
        if (open) setOpen(false)
    }, [location])

    useEffect(() => {
        if (loading === animateLoading) return

        if (loading) {
            animateLoadingStart.current = new Date().getTime();
            setAnimateLoading(true)
        }

        if (!loading) {
            const delay = 1200 - (new Date().getTime() - animateLoadingStart.current) % 1200;

            setTimeout(() => {
                setAnimateLoading(false)
            }, delay);
        }
    }, [loading])

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick)

        return () => {
            document.removeEventListener('click', handleOutsideClick)
        }
    }, [open])

    const handleOutsideClick = (event) => {
        if (open && ref.current && !ref.current.contains(event.target)) {
            setGroupOpen(null)
            setOpen(false)
        }
    }

    const classes = [styles.navigation, language]
    open && classes.push('show-navigation')
    isHome && classes.push('home')

    return (
        <div className={classes.join(' ')}>
            <span
                className="menu"
                onClick={() => setOpen(!open)}>
                <span />
            </span>
            <div ref={ref}>
                <div>
                    <NavLink
                        to={language === 'de' ? '/' : '/fr'}
                        className={'home fillSecondaryHover ' + (animateLoading ? ' loading' : '')}>
                        <span />
                        <Logo />
                    </NavLink>
                    <div>
                        <Link
                            to={translation}
                            className="language colorSecondaryHover">
                            {language === 'de' ? 'Français' : 'Deutsch'}
                        </Link>
                        <ul>
                            {navigation.main.map((entry, i) => (
                                <Group
                                    colorSecondary={colorSecondary}
                                    data={entry}
                                    key={i}
                                    index={i}
                                    groupOpen={groupOpen}
                                    onClick={(index) => {
                                        console.log(index)
                                        setGroupOpen(index)
                                    }} />
                            ))}
                        </ul>
                        <ul>
                            {navigation.meta.map((entry, i) => (
                                <Button data={entry} colorSecondary={colorSecondary} key={i} />
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => {
    const { navigation, colorSecondary, language } = state.config
    const { loading, pages } = state.pages

    const data = pages.find(page => page.path === props.location.pathname)
    const translation = data && data.translation ? data.translation : language === 'de' ? '/fr' : '/'

    console.log(data)

    return {
        navigation: navigation[language],
        colorSecondary,
        language,
        loading,
        translation
    }
}

const mapDispatchToProps = dispatch => ({
    getConfig: () => dispatch(getConfig()),
})

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Navigation))