import React, { useState, useEffect} from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter, Link } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'

import { getPage, setLoading } from 'actions'

import Loading from 'components/loading'

import Map from './contents/map'
import Text from './contents/text'
import TextImage from './contents/textImage'
import Teaserlist from './contents/teaserlist'
import Projectslist from './contents/projectslist'
import Calendar from './contents/calendar'
import Catalogue from './contents/catalogue'
import Search from './contents/search'
import Newsletter from './contents/newsletter'

import Application from './contents/application/Application'
import Login from './contents/application/Login'
import Signup from './contents/application/Signup'
import ChangePassword from './contents/application/ChangePassword'
import RequestPassword from './contents/application/RequestPassword'

import styles from './Page.module.sass'

const contents = {
    text: Text,
    textImage: TextImage,
    map: Map,
    teaserWithSubpages: Teaserlist,
    projectList: Projectslist,
    calendar: Calendar,
    catalogue: Catalogue,
    search: Search,
    newsletter: Newsletter,

    
    login: Login,
    signup: Signup,
    changePassword: ChangePassword,
    requestPassword: RequestPassword,
    application: Application
}

const Index = ({location, getPage, setLoading, data, language, colorSecondary, path, type, style}) => {

    console.log(path, type)

    useEffect(() => {
        console.log('load page')
        window.scrollTo(0, 0)
        
        if(data && data.static) {
            
        } else {
            getPage(path)
        }

        if(!data) setLoading(true)

    }, [])

    useEffect(() => {
		if(data) {
            if(data.template!=='map') setLoading(false)

            document.title = String(data.title).replace(/&#038;/g, "&").replace(/&#8211;/g, "–") + ' – Impuls neue Musik';
            
            const hash = location.hash.substr(1)
            console.log(hash)

            if (hash && document.getElementById(hash)) {
                console.log('test')
                document.getElementById(hash).scrollIntoView()
            }

		}		
	}, [data])

    //console.log(data)

    //if(!data) return null

    const Content = data ? contents[data.template] : null;

    let classNames = [styles.page]
    data && data.template && classNames.push(data.template)
    type && classNames.push(type)
    
    /*

    {!data &&
				<Loading />
            }
            
    */

    return (
        <div className={classNames.join(' ')} style={style}>
            {data &&
                <CSSTransition
                    in
                    classNames="animate-content"
                    appear={true}
                    timeout={500}>
                    <div>
                        {data.template !== 'map' &&
                            <div className={'title' + (data.navigation ? ' navigation' : '')}>
                                {data.navigation &&
                                    <Link 
                                        to={data.navigation.previous}
                                        className="previous"
                                        style={{color: colorSecondary}}>&lt;</Link>
                                }
                                <h2 style={{color: colorSecondary}}>{data.title}</h2>
                                {data.navigation &&
                                    <Link
                                        to={data.navigation.next}
                                        className="next"
                                        style={{color: colorSecondary}}>&gt;</Link>
                                }
                            </div>
                        }
                        {data.intro &&
                            <div className="introduction">
                                {data.intro.title &&
                                    <h3 style={{color: colorSecondary}} dangerouslySetInnerHTML={{__html: data.intro.title}} />
                                }
                                {data.intro.text &&
                                    <div className="rich" dangerouslySetInnerHTML={{__html: data.intro.text}} />
                                }
                                {data.intro.image &&
                                    <div className="image" style={{maxWidth: `${640/data.intro.image.height * data.intro.image.width}px`}}>
                                        <div style={{
                                            backgroundImage: `url(${data.intro.image.path})`, 
                                            paddingBottom: `${data.intro.image.height/data.intro.image.width*100}%`,
                                        }} />
                                    </div>
                                }
                            </div>
                        }
                        <Content 
                            data={data.content}
                            language={language}
                            colorSecondary={colorSecondary}
                        />
                    </div>
                </CSSTransition>	
            }
        </div>
    );
};

const mapStateToProps = (state, props) => {
    const { language, colorSecondary } = state.config
    const { pages } = state.pages
    
    const data = pages.find(page => page.path === props.path) 

    //console.log(state.pages)

	return {
        language,
        colorSecondary,
        data
	}
}

const mapDispatchToProps = dispatch => ({
    getPage: (path) => dispatch(getPage(path)),
    setLoading: (loading) => dispatch(setLoading(loading)), 
})

function propsAreEqual(prev, next) {
    if(!prev.colorSecondary && next.colorSecondary) {
        return false
    }

    if(prev.type !== next.type) {
        return false
    }

    if(!prev.style !== next.style) {
        return false
    }

	if(prev.data) {
		return true
    }
    
    console.log('cancel render')
}

const enhance = compose(
    withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(React.memo(Index, propsAreEqual));