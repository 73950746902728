import React, { useEffect } from 'react';

function Substr2(str, length, exclude = /([\s])/) {
    var i = 0
    for (var count = 0 ; i < str.length && count < length; i++) {
      if (!str[i].match(exclude)) {
        count++
      }
    }
    
    return str.substr(0, i)
  }

const Textarea = ({labels, name, value, onChange, errors, maxLength}) => {

    const handleChange = (event) => {
        let value = event.target.value; 

        
        if(maxLength) {
            value = value.slice(0, maxLength);
        }
        
        
        onChange({
            name, 
            value
        })
    }

    /*
    useEffect(() => {
        let updatedValue = value

        if(maxLength) {
            updatedValue = Substr2(updatedValue, maxLength);
        }

        onChange({
            name, 
            value: updatedValue
        })

    },[value, maxLength])
    */

    let classes = ['textarea']
    if(errors && errors.findIndex(error => error.name === name)>-1) classes.push('error')

    return (
        <div className={classes.join(' ')}>
            {labels &&
                <label>{labels.de} <span className="fr">{labels.fr}</span></label>
            }
            {maxLength &&
                <span className="counter">{value ? value.toString().length : 0} / {maxLength}</span>
            }
           
            <textarea value={value} onChange={handleChange} />
        </div>
    );
};

export default Textarea;