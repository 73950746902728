import React from 'react';
import styles from './Catalogue.module.sass'

import { FormatBytes } from 'helper'

const Index = ({data, colorSecondary}) => {

    let style = {
        backgroundImage: `url(${data.image.path})`, 
        paddingBottom: `${data.image.height/data.image.width*100}%`,
    }
    
    return (
        <div className={styles.catalogue}>
            <div style={{maxWidth: `${640/data.image.height * data.image.width}px`}}>
                {data.image && 
                    <div className="image" style={style} />
                }
                {data.file &&
                    <> 
                        <a href={data.file.path} className="area" download={data.file.path.split('\\').pop().split('/').pop()}></a>
                        <p style={{color: colorSecondary}}>{data.file.path.split('.').pop().toUpperCase()}, {FormatBytes(data.file.size)}</p>
                    </>
                }
            </div>
        </div>
    );
};

export default Index;