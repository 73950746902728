import React from 'react';
import styles from './Newsletter.module.sass'
import MailchimpSubscribe from 'components/page/parts/mailchimp'



const url = "https://impulsneuemusik.us8.list-manage.com/subscribe/post?u=f04c5d1d56d1e11efc2ef4bcd&id=193a426845";

// simplest form (only email)



const Index = ({language}) => {

    const SimpleForm = () => <MailchimpSubscribe 
                            url={url} 
                            submitText={language==='de' ? 'anmelden' : 's\'inscrire' } />

    return (
        <div className={styles.newsletter}>
            <div><h3>{language==='de' ? 'NEWSLETTER ANMELDEN' : 'INSCRIPTION NEWSLETTER' }</h3>
            <MailchimpSubscribe
                url={url}
                render={({ subscribe, status, message }) => (
                <div>
                    <SimpleForm onSubmitted={formData => subscribe(formData)} />
                </div>
                )}
            />
            </div>
            <div>
            <h3>{language==='de' ? 'NEWSLETTER ABMELDEN' : 'DÉSINSCRIPTION NEWSLETTER' }</h3>
            <p><a href="https://impulsneuemusik.us8.list-manage.com/unsubscribe?u=f04c5d1d56d1e11efc2ef4bcd&id=193a426845">{language==='de' ? 'abmelden' : 'se désinscrire' }</a></p>
            </div>
        </div>
    );
};

export default Index;