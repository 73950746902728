import React, { useState } from 'react';
import { Link } from 'react-router-dom'

const FileUpload = ({type, onChange, onCancel, labels, buttonLabels, value, withPreview, errors, isLoggedin}) => {
    const [ fileData, setFileData ] = useState(null)

    const selectFile = (e) => {
        e.preventDefault()

        console.log('selectFile')

	    let reader = new FileReader()
	    let file = e.target.files[0]

        let filename = file.name;

        console.log(file)

	    reader.onloadend = () => {
	      /*
	      this.setState({
	        file: file,
	        imagePreviewUrl: reader.result
	      })
	      */
          //this.props.onSaveImage(reader.result)

          onChange({
                type: type,
                name: filename,
                base64: reader.result
          })

          setFileData({
                type: type,
                name: filename,
                base64: reader.result
        });

	      console.log(reader.result)
	    }

	    reader.readAsDataURL(file)
    }

    const cancelFile = () => {
        setFileData(null);
        onChange({
            type: type,
        })
    }

    //console.log(fileData)

    let active = false
    let fileName = null
    let style = null

    if(fileData) {
        active = true
        fileName = fileData.name
        style = {backgroundImage: `url(${fileData.base64})`}
    }

    if(value) {
        active = true
        fileName = <a href={value.url} target="_blank">{value.filename}</a>
        style = {backgroundImage: `url(${value.url})`}
    }

    //console.log(value)

    let classes = ['file']
    if(withPreview) classes.push('withPreview')
    if(errors && errors.findIndex(error => error.name === type)>-1 && !fileName) classes.push('error')

    return (
        <div className={classes.join(' ')}>
            {labels &&
                <label>{labels.de} <span className="fr">{labels.fr}</span></label>
            }

            {withPreview &&
                <div className="preview" style={style}></div>
            }

            {active &&
                <>
                <span>{fileName}</span>
                {isLoggedin && 
                    <span className="remove" onClick={() => cancelFile()}>Löschen <span className="fr">Supprimer</span></span>
                }
                </>
            }

            {(!active && isLoggedin) &&
                 <>
                    <input type="file" id={'file' + type} onChange={selectFile} />
                    <span className="select" onClick={() => document.getElementById('file' + type).click()}>{buttonLabels.de} <span className="fr">{buttonLabels.fr}</span></span>
                </>
            }
            
        </div>
    );
};

export default FileUpload;