import React from 'react';
import styles from './Text.module.sass'

const Index = ({data}) => {
    return (
        <div className={styles.text + ' rich'} style={{columnCount: 3}} dangerouslySetInnerHTML={{__html: data.text}}>
        </div>
    );
};

export default Index;