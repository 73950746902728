import { eventConstants } from '../constants'
import moment from 'moment'

const initialState = {
    events: {
        de: {
            events: [],
            years: []
        },
        fr: {
            events: [],
            years: []
        }
    }
}

export function events(state = initialState, action) {
    console.log(action.type)
    switch (action.type) {
        case eventConstants.GETEVENTS_REQUEST:

            let newState = {...state.events }

            action.years.forEach(year => {
                newState[action.language].years.push({ year: year, state: 'loading' })
            });

           

            //test2[action.language].years = years

            //test2[action.language].years = [...test2[action.language].years, { year: action.year, state: 'loading'}]

            //console.log(test2)

            console.log('GETEVENTS_REQUEST')
            console.log(newState)

            return {
                ...state,
                newState
            }
        case eventConstants.GETEVENTS_SUCCESS:

            const language = action.language;
            const eventsNew = {...state.events[language] }

            let test2 = action.events.events.map((event, i) => ({
                ...event,
                moment: moment(event.date, 'DD-MM-YYYY'),
                id: `${moment().unix()}${i}`
            }))

            let eventsNew2 = [...eventsNew.events, ...test2]


            /* */

            if (!state.events[language].length) {

                let currentDate = moment().unix()

                const closestEvent2 = eventsNew2.reduce((closest, event, i) => {


                    let timestampCurrent = moment(event.date, 'DD-MM-YYYY').unix()

                    event.time = timestampCurrent


                    //let timestampBest = 
                    //console.log(moment(event.date, 'DD-MM-YYYY'), timestamp-startOfMonth)

                    let diff = timestampCurrent - currentDate

                    if (diff >= 0) {
                        return !closest || diff < moment(closest.date, 'DD-MM-YYYY').unix() - currentDate ? event : closest
                    }

                    return closest

                }, null);

                console.log('CLOSEST EVENT:')
                console.log(closestEvent2)

                if (closestEvent2) closestEvent2.next = true

            }

            console.log(eventsNew2)

            /* */

            action.events.years.forEach(temp => {
                const index = eventsNew.years.findIndex(year => year.year === temp)

                eventsNew.years[index] = { year: temp, state: 'loaded' }
            });

           

            let test = {...state.events }
            test[language].events = eventsNew2;
            test[language].years = eventsNew.years

            console.log(test)

            return {
                ...state,
                events: test
            }
        case eventConstants.GETEVENTS_FAILURE:
            console.log('ss')
            return {
                error: action.error
            }

        default:
            return state
    }
}