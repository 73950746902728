export const authenticationConstants = {
    SIGNUP_REQUEST: 'AUTHENTICATION_SIGNUP_REQUEST',
    SIGNUP_SUCCESS: 'AUTHENTICATION_SIGNUP_SUCCESS',
    SIGNUP_FAILURE: 'AUTHENTICATION_SIGNUP_FAILURE',

    VALIDATE_REQUEST: 'AUTHENTICATION_VALIDATE_REQUEST',
    VALIDATE_SUCCESS: 'AUTHENTICATION_VALIDATE_SUCCESS',
    VALIDATE_FAILURE: 'AUTHENTICATION_VALIDATE_FAILURE',

    LOGIN_REQUEST: 'AUTHENTICATION_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'AUTHENTICATION_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'AUTHENTICATION_LOGIN_FAILURE',
    
    LOGOUT_REQUEST: 'AUTHENTICATION_LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'AUTHENTICATION_LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'AUTHENTICATION_LOGOUT_FAILURE',

    REQUESTPASSWORD_REQUEST: 'AUTHENTICATION_REQUESTPASSWORD_REQUEST',
    REQUESTPASSWORD_SUCCESS: 'AUTHENTICATION_REQUESTPASSWORD_SUCCESS',
    REQUESTPASSWORD_FAILURE: 'AUTHENTICATION_REQUESTPASSWORD_FAILURE',

    CHANGEPASSWORD_REQUEST: 'AUTHENTICATION_CHANGEPASSWORD_REQUEST',
    CHANGEPASSWORD_SUCCESS: 'AUTHENTICATION_CHANGEPASSWORD_SUCCESS',
    CHANGEPASSWORD_FAILURE: 'AUTHENTICATION_CHANGEPASSWORD_FAILURE',
};
