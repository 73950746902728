import React from 'react';

import Input from './Input'

const Row = ({ type, data, onChange, errors, labels, index, onRemove, isLoggedin, placeHolder }) => {

    const handleChange = (props) => {
        onChange({ ...data, [props.name]: props.value })
    }

    /*
    const handleBlur = () => {
        let value = data.value

        console.log()

        if (isNaN(value) || !value) {
            value = ''
        } else {
            //value = parseInt(value)
            value = parseFloat(value)
            value = value.toFixed(2);
        }

        console.log(value)

        onChange({ ...data, value })
    }
    */

    return(
        <div>
            <div className="cols">
                <div style={{position: 'relative'}}>
                    {(!data.name && placeHolder) &&
                        <span className="placeholder"><span>{placeHolder.de} <span className="fr">{placeHolder.fr}</span></span></span>
                    }
                    <Input
                        name="name"
                        value={data.name}
                        onChange={props => handleChange(props)}
                        errors={errors}
                    />
                </div>
                <div>
                    <Input
                        name="value"
                        value={data.value}
                        onChange={props => handleChange(props)}
                        
                        errors={errors}
                        addClasses={['currency']}
                        addText="€"
                        type="currency"
                    />
                </div>
                <div>
                {(index>0 && isLoggedin) &&
                <span className="remove" style={{marginTop: '0px'}} onClick={() => onRemove(index)}>{labels.de} <span className="fr">{labels.fr}</span></span>
            }
                </div>
            </div>
            
        </div>
    )
}


const Repeater = ({ labels, name, value, onChange, errors, removeLabels, isLoggedin, className, placeHolders }) => {

    const handleChange = (i, data) => {

        const valueNew = value
        valueNew[i] = data

        onChange({ name, value: valueNew })
    }

    const addRow = () => {

        let data = value.length ? value : [{}];
        data.push({})

        onChange({ name, value: data })
    }

    const removeRow = (index) => {
        let data = value
        data.splice(index, 1)

        onChange({ name, value: data })
    }

    let data = value.length>0 ? value : [{}];

    let repeaterErrors;

    if(errors && errors.length) {
        repeaterErrors = errors.find(error => error.name === name)

        if(repeaterErrors) repeaterErrors = repeaterErrors.errors
    }

    //console.log('repeaterErrors')
    //console.log(repeaterErrors)

    let classes = ['repeater']
    if(className) classes.push(className)


    return (
        <div className={classes.join(' ')}>
            {data.map((row, i) => {
                
                
                const rowErrors = repeaterErrors && repeaterErrors[i] ? repeaterErrors[i] : []
                //console.log('>>>')
                //console.log(rowErrors)

                return <Row 
                    key={i} 
                    type={name}
                    data={row}
                    onChange={data => handleChange(i, data)}
                    errors={rowErrors} 
                    index={i} 
                    labels={removeLabels} 
                    onRemove={index => removeRow(index)}
                    isLoggedin={isLoggedin}
                    placeHolder={placeHolders[i]}
                />
            })}
            {isLoggedin && 
                <span className="add" style={{marginTop: '20px'}} onClick={() => addRow()}>{labels.de} <span className="fr">{labels.fr}</span></span>
            }
        </div>
    );
};

export default Repeater;