import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { changePassword } from 'actions'
import { withRouter, Link } from 'react-router-dom'

import Input from 'components/page/parts/form/Input'

import styles from './Application.module.sass'
import styles2 from './ApplicationMain.module.sass'

const ChangePassword = ({login, changePassword, authentication, history, location, language}) => {
    const [data, setData] = useState({
        password: ''
    });
    /*
    useEffect(() => {

        const query = new URLSearchParams(location.search)

		if(query.get('email') && query.get('key')) { 
            const email = decodeURIComponent(query.get('email'));
            const hash = decodeURIComponent(query.get('key'));

            changePassword(email, hash);
            //alert('ss')
		}



    }, [])
    */
    
    /*
    useEffect(() => {
        //console.log(authentication)
		if(authentication.isLoggedin) {
            history.push('/application')
        }
	}, [authentication])
    */

    const onChange = (props) => {
        const newData = { ...data }
        newData[props.name] = props.value

        setData(newData)
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        console.log('submit')

        const query = new URLSearchParams(location.search)

        if(query.get('email') && query.get('key')) { 
            const email = decodeURIComponent(query.get('email'));
            const hash = decodeURIComponent(query.get('key'));

            changePassword(email, data.password, hash)
        }   
    }

    let linkLanguage = language === 'fr' ? '/fr' : '';

    return (
        <div className={`${styles2.app} ${styles.application}`}>
            <h1>Passwort ändern <span className="fr">Changer le mot de passe</span></h1>
            {!authentication.isChanged
                ? <form>
                    <div>
                        <Input
                            labels={{ de: 'Passwort', fr: 'Mot de passe' }}
                            name="password"
                            type="password"
                            value={data.password}
                            onChange={props => onChange(props)}
                        />
                        {authentication.error &&
                            <p className="error" style={{color: 'red', marginTop: '15px'}} dangerouslySetInnerHTML={{__html: authentication.error}}></p>
                        }
                        <div className="cols3" style={{marginTop: '25px'}}>
                            <div>
                                <span className="button submit" onClick={handleSubmit}>
                                    Passwort ändern<br />
                                    <span className="fr">Changer le mot de passe</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </form>
                : <div style={{marginTop: '20px'}}>
                   Ihr Passwort wurde geändert. <Link to={`${linkLanguage}/login/`}>Zum Login</Link> <span className="fr">Votre mot de passe a été modifié. <Link to={`${linkLanguage}/login/`}>Me connecter</Link></span>
                </div>
            }
        </div>
    )
}

const mapStateToProps = (state, props) => {
    const { authentication } = state
   
    return {
        authentication
    }
}

const mapDispatchToProps = dispatch => ({
    changePassword: (email, password, hash) => dispatch(changePassword(email, password, hash)),
})

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangePassword))