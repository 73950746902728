import { put, takeEvery, delay, takeLatest, all, fork, call, select } from 'redux-saga/effects'

import { applicationConstants } from '../constants';
import { fetchData } from '../api';

const getItems = state => state.authentication;

export function* handleGetApplication(payload) {
    console.log(payload)
    try {

        let payloadNew = {
            action: 'getApplication',
        }

        if(payload.id) {
            payloadNew.id = payload.id;
            payloadNew.key = payload.key;
        } else {

            const items = yield select(getItems);
            
            payloadNew.email = items.email;
            payloadNew.token = items.token;
            console.log(items)
        }

        

        let { data } = yield call(fetchData, payloadNew);

        yield put({ type: applicationConstants.GET_APPLICATION_SUCCESS, application: data })

    } catch (error) {
        yield console.log('ss')
        //yield put(setError(error.toString()));
    }
}

export function* handlePostApplication(payload) {

    console.log(payload)

    try {

        const items = yield select(getItems);

        let payloadNew = {
            ...payload.data,
            action: 'postApplication',
            email: items.email,
            token: items.token
        }

        let { data } = yield call(fetchData, payloadNew);

        yield put({ type: applicationConstants.POST_APPLICATION_SUCCESS, application: data.data })

    } catch (error) {
        yield console.log('error')
        //yield put(setError(error.toString()));
    }


}

export default function* watchPageLoad() {
    yield takeEvery(applicationConstants.GET_APPLICATION_REQUEST, handleGetApplication);
    yield takeEvery(applicationConstants.POST_APPLICATION_REQUEST, handlePostApplication);
}