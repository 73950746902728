import { all } from 'redux-saga/effects';

import config from './config';
import pages from './pages';
import events from './events';
import authentication from './authentication';
import application from './application';

console.log('allSagas')

export default function* rootSaga() {
    yield all([config(), pages(), events(), authentication(), application()]);
}