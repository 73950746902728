import { pageConstants } from '../constants'
import moment from 'moment'

const initialState = {	
    pages: [
        {
            path: '/',
            template: 'map',
            title: 'Home'
        },
        {
            path: '/fr',
            template: 'map',
            title: 'Home'
        },
        {
            path: '/login/',
            template: 'login',
            title: 'Antrag',
            static: true
        },
        {
            path: '/fr/login/',
            template: 'login',
            title: 'Dossier',
            static: true
        },
        {
            path: '/signup/',
            template: 'signup',
            title: 'Antrag',
            static: true
        },
        {
            path: '/fr/signup/',
            template: 'signup',
            title: 'Dossier',
            static: true
        },
        {
            path: '/request/',
            template: 'requestPassword',
            title: 'Antrag',
            static: true
        },
        {
            path: '/fr/request/',
            template: 'requestPassword',
            title: 'Dossier',
            static: true
        },
        {
            path: '/change/',
            template: 'changePassword',
            title: 'Antrag',
            static: true
        },
        {
            path: '/fr/change/',
            template: 'changePassword',
            title: 'Dossier',
            static: true
        },
        {
            path: '/application/',
            template: 'application',
            title: 'FÖRDERANTRAG / DOSSIER DE CANDIDATURE',
            static: true
        },
        {
            path: '/fr/application/',
            template: 'application',
            title: 'FÖRDERANTRAG / DOSSIER DE CANDIDATURE',
            static: true
        }
    ],
    loading: false
}

export function pages(state = initialState, action) {
    //console.log(action.type)
    switch (action.type) {
        case pageConstants.GETPAGE_REQUEST:
            //console.log('ss')
            return {
                ...state
            }
        case pageConstants.GETPAGE_SUCCESS:

            const { pages, events } = state;
			const index = pages.findIndex(page => page.path === action.page.path)

			if(index>-1) {
				pages[index] = action.page
			} else {
				pages.push(action.page)
            }
            
           
            return {
                ...state,
                pages,
            }
        case pageConstants.GETPAGE_FAILURE:
            console.log('ss')
            return { 
                error: action.error
            }

        case pageConstants.SETLOADING_SUCCESS:
            //console.log('ss')
            return {
                ...state,
                loading: action.loading
            }

        default:
            return state
    }
}