import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { signup } from 'actions'

import Input from 'components/page/parts/form/Input'
import Checkbox from 'components/page/parts/form/Checkbox'

import styles from './Application.module.sass'
import styles2 from './ApplicationMain.module.sass'
import { withRouter, Link } from 'react-router-dom'

const Signup = ({ authentication, signup, language }) => {
    const [data, setData] = useState({
        email: '',
        password: '',
        privacy: false
    });
    const [errors, setErrors] = useState([])

    const onChange = (props) => {
        const newData = { ...data }
        newData[props.name] = props.value

        setData(newData)
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        console.log('submit')

        if(authentication.isSigningIn) {
            return false
        }

        if(data.privacy) {
            signup(data.email, data.password)
        } else {
            const newErrors = []
            newErrors.push({ name: 'privacy' })
            setErrors(newErrors);
        }
    }

    let linkLanguage = language === 'fr' ? '/fr' : '';

    return (
        <div className={`${styles2.app} ${styles.application}`}>
            <h1>Registrierung <span className="fr">S'inscrire</span></h1>
            {!authentication.isRegistered
                ? <form>
                    <div>
                        <Input
                            labels={{ de: 'Email' }}
                            name="email"
                            value={data.email}
                            onChange={props => onChange(props)}
                        />
                        <Input
                            labels={{ de: 'Passwort', fr: 'Mot de passe' }}
                            name="password"
                            type="password"
                            value={data.password}
                            onChange={props => onChange(props)}
                        />
                       
                        <Checkbox
                            data={[
                                { value: 'true', labels: { all: 'Hiermit stimmen Sie den <a href="http://www.impulsneuemusik.com/datenschutzerklaerung/" target="_blank">Datenschutzbestimmungen</a> von Impuls neue Musik zu. <span class="fr">J’accepte la <a href="http://www.impulsneuemusik.com/fr/protection-des-donnees/"  target="_blank">politique de confidentialité</a> d’Impuls neue Musik.</span>' } },
                            ]}
                            name="privacy"
                            value={data.privacy}
                            onChange={props => onChange(props)}
                            style={{marginTop: '15px'}}
                            errors={errors}
                            className="long"
                        />
                    {authentication.error &&
                        <p className="error" style={{color: 'red', marginTop: '15px'}} dangerouslySetInnerHTML={{__html: authentication.error}}></p>
                    }
                    </div>
                    <div className="cols3" style={{marginTop: '25px'}}>
                        <div>
                            <span className={'button submit' + (authentication.isSigningIn ? ' disabled': '')} onClick={handleSubmit}>
                                Registrieren<br />
                                <span className="fr">S'inscrire</span>
                            </span>
                        </div>
                    </div>
                    <p style={{marginTop: '15px'}}>Schon registriert? <Link to={`${linkLanguage}/login/`}>Zum Login</Link> <span className="fr">Déjà inscrit ? <Link to={`${linkLanguage}/login/`}>Me connecter</Link></span></p>
                </form>
                : <div  style={{marginTop: '20px'}}>
                    Sie haben eine Email bekommen. Bitte bestätigen Sie Ihre Email-Adresse.
                </div>
            }

        </div>
    )
}

const mapStateToProps = (state, props) => {
    const { authentication } = state

    return {
        authentication
    }
}

const mapDispatchToProps = dispatch => ({
    signup: (email, password) => dispatch(signup(email, password)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Signup)