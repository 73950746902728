import React from 'react';
import styles from './TextImage.module.sass'

const Index = ({data, colorSecondary}) => {

    let style = data.withColor ? {backgroundColor: colorSecondary} : {paddingBottom: `${data.image.height/data.image.width*100}%`}

    return (
        <div className={styles.textImage}>
            {data.image && 
                <div>
                    <div className={'image' + (data.withColor ? ' colored' : '')} style={{...style, backgroundImage: `url(${data.image.path})`}}></div>
                </div>
            }
            <div className="rich" dangerouslySetInnerHTML={{__html: data.text}} />
        </div>
    );
};

export default Index;