import React, { useState } from 'react';

const Select = ({ labels, name, type, value, onChange, addText, addClasses, errors, placeHolder, options, smallLabel, custom = true }) => {
    const [active, setActive] = useState(false)

    const handleChange = (event) => {
        onChange({
            name,
            value: event.target.value
        })
    }

    type = type ? type : 'text'

    let classes = ['input select']
    if (addClasses) classes = classes.concat(addClasses)
    if (errors && errors.findIndex(error => error.name === name) > -1) classes.push('error')

    let list = options.map((item, idx) => {
        return (
            <span
                key={idx}
                onClick={() => {
                    console.log(item)
                    onChange({
                        name,
                        value: item.value
                    })
                    setActive(false)
                }}>{item.labels.de} <span className="fr">{item.labels.fr}</span></span>
        );

    });

    const selectedOption = options.find(item => item.value === value)

    return (
        <div className={classes.join(' ')}>
            {labels &&
                <label className={smallLabel ? 'small' : ''}>{labels.de} <span className="fr">{labels.fr}</span></label>
            }
            {custom ? (
                <input
                    type={type}
                    value={value}
                    onChange={handleChange}
                    placeHolder={placeHolder}
                    autoComplete="off"
                    onFocus={() => setActive(true)}
                    onBlur={() => {
                        setTimeout(() => {
                            setActive(false)
                        }, 200);
                    }} />
            ) : (
                <div
                    className="selected"
                    onClick={() => setActive(!active)}>
                    {selectedOption && (<span>{selectedOption.labels.de} <span className="fr">{selectedOption.labels.fr}</span></span>)}
                </div>
            )}

            {active &&
                <div className="select">
                    {list}
                </div>
            }
        </div>
    );
};

export default Select;