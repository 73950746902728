import { authenticationConstants } from "../constants";

let user = JSON.parse(localStorage.getItem("user2023"));
/*
user = {
  token: "$2y$12$3MQxRfCC.twby789oKWMD.foqGZX.Za8MYmgSq2GpPqICsq8f3Ak2",
  email: "info@stefanbott.de",
};
*/

/*
user = {
  token: "$2y$12$KgVjm7GnzmOGvZB4GW/17u2RI601J.b/sltBvHRXJBiOf0VjOicqe",
  email: "i.standke@jotpe.com",
};
*/

const initialState = user
  ? {
      isLoggedin: true,
      token: user.token,
      email: user.email,
    }
  : {};

export function authentication(state = initialState, action) {
  console.log(action);
  switch (action.type) {
    case authenticationConstants.SIGNUP_REQUEST:
      return {
        isSigningIn: true,
      };

    case authenticationConstants.SIGNUP_SUCCESS:
      return {
        isSigningIn: false,
        isRegistered: true,
      };

    case authenticationConstants.SIGNUP_FAILURE:
      return {
        isSigningIn: false,
        error: action.error,
      };

    case authenticationConstants.VALIDATE_SUCCESS:
      return {
        validated: true,
      };

    case authenticationConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
      };
    case authenticationConstants.LOGIN_SUCCESS:
      return {
        isLoggedin: true,
        token: action.token,
        email: action.email,
      };
    case authenticationConstants.LOGIN_FAILURE:
      return { error: action.error };
    case authenticationConstants.LOGOUT_SUCCESS:
      return {};

    case authenticationConstants.REQUESTPASSWORD_SUCCESS:
      return {
        isRequested: true,
      };

    case authenticationConstants.REQUESTPASSWORD_FAILURE:
      return { error: action.error };

    case authenticationConstants.CHANGEPASSWORD_SUCCESS:
      return {
        isChanged: true,
      };

    case authenticationConstants.CHANGEPASSWORD_FAILURE:
      return { error: action.error };

    default:
      return state;
  }
}
