import { configConstants, pageConstants, eventConstants, authenticationConstants, applicationConstants } from '../constants';

const getConfig = () => ({
    type: configConstants.GETCONFIG_REQUEST
});

const setLanguage = language => ({
    type: configConstants.SETLANGUAGE_REQUEST,
    language,
});

const getPage = path => ({
    type: pageConstants.GETPAGE_REQUEST,
    path,
});

const getEvents = (language, years) => ({
    type: eventConstants.GETEVENTS_REQUEST,
    language,
    years,
});

const setLoading = loading => ({
    type: pageConstants.SETLOADING_REQUEST,
    loading,
});

/* */

const signup = (email, password) => ({
    type: authenticationConstants.SIGNUP_REQUEST,
    email,
    password,
});

const validate = (email, hash) => ({
    type: authenticationConstants.VALIDATE_REQUEST,
    email,
    hash,
});

const login = (email, password) => ({
    type: authenticationConstants.LOGIN_REQUEST,
    email,
    password,
});

const logout = () => ({
    type: authenticationConstants.LOGOUT_REQUEST
});

const requestPassword = (email) => ({
    type: authenticationConstants.REQUESTPASSWORD_REQUEST,
    email
});

const changePassword = (email, password, hash) => ({
    type: authenticationConstants.CHANGEPASSWORD_REQUEST,
    email,
    password,
    hash
});

/* */

const getApplication = (id, key) => ({
    type: applicationConstants.GET_APPLICATION_REQUEST,
    id,
    key,
});

const postApplication = (data) => ({
    type: applicationConstants.POST_APPLICATION_REQUEST,
    data,
});

export {
    getConfig,
    setLanguage,
    getPage,
    getEvents,
    setLoading,

    signup,
    validate,
    login,
    logout,
    requestPassword,
    changePassword,

    getApplication,
    postApplication
}