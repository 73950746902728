import React, { useEffect, useState, useRef} from 'react';
import { connect } from 'react-redux'
import { withRouter, Route, Switch } from 'react-router-dom'

import './App.sass';
import { setLanguage } from './actions'

import Navigation from 'components/navigation'
import Page from 'components/page'
import stylesNavigation from 'components/navigation/Navigation.module.sass'

import stylesApplication from 'components/page/contents/application/ApplicationMain.module.sass'

function App({location, setLanguage, language, colorSecondary}) {
    const [ pages, setPages ] = useState([])
    const lastPath = useRef(null)

    let currentPath = location.pathname

    useEffect(() => {
        if(colorSecondary) {
            var style = document.createElement('style');
            style.type = 'text/css';
            style.innerHTML = `.borderColorSecondaryHover:hover .image {border-color: ${colorSecondary} !important} 
                .colorSecondaryHover:hover {color: ${colorSecondary}} 
                .bgSecondaryHover:hover {background: ${colorSecondary} !important}
                a.home:hover span, a.home:hover span:before, a.home:hover span:after {background: ${colorSecondary}}
                .fillSecondaryHover:hover svg {fill: ${colorSecondary} !important}

                .${stylesApplication.app} form .error input[type="text"],
                .${stylesApplication.app} form .error input[type="password"],
                .${stylesApplication.app} form .error input[type="number"],
                .${stylesApplication.app} form .error textarea,
                .${stylesApplication.app} form .error.file .preview,
                .${stylesApplication.app} form .radio.error .icon,
                .${stylesApplication.app} form .checkbox.error .icon {
                    border-color: red;
                }

                .${stylesApplication.app} form .error label {
                    color: red;
                }

                
                .${stylesApplication.app} form h1,
                .${stylesApplication.app} form h2,
                .${stylesApplication.app} form .file span a,
                .${stylesApplication.app} form .finance h3 {
                    color: ${colorSecondary};
                }

                .${stylesApplication.app} form .button.submit,
                .${stylesApplication.app} form .colsButtons .button,
                .${stylesApplication.app} form .repeater .add,
                .${stylesApplication.app} form .repeater .remove,
                .${stylesApplication.app} form .file .remove,
                .${stylesApplication.app} form .file .select {
                    background: ${colorSecondary};
                }

                @media (max-width: 1023px) {
                    .${stylesNavigation.navigation} > div > div > div {
                        background: ${colorSecondary}
                    }
                }`;
            document.getElementsByTagName('head')[0].appendChild(style);
        }

    }, [colorSecondary])

    useEffect(() => {
        let languageNew = location.pathname.split('/')[1] === 'fr' ? 'fr' : 'de'

        if (languageNew !== language) {
            setLanguage(languageNew)
        }

        if(lastPath.current !== location.pathname) {
            lastPath.current = location.pathname

            const pagesNew = [...pages, location.pathname]     
            setPages( pagesNew )

            if(pagesNew.length>1) {
                setTimeout(() => {
                    
                    const pagesNew2 = [...pagesNew]
                    pagesNew2.splice(0, 1)
                    setPages( pagesNew2 )
                
                }, 100);
            }
        }

    }, [location])

    console.log('-----------------')
    console.log(pages)
   
    return (
        <>
            <Navigation />
            <div id="content">
                {pages.map((path, i) => {

                    let type = 'in'
                    let style = {}
                    
                    console.log(i, pages.length-1)

                    if(i<pages.length-1) {
                        console.log('out')
                        type = 'out'
                        style = {marginTop: `-${window.pageYOffset}px`}
                    }

                    console.log(path, type)
                    
                    return <Page path={path} key={path} type={type} style={style} />

                })}
            </div>
        </>
    );
}

const mapStateToProps = (state, props) => {
    const { pages } = state.pages
    const { language, colorSecondary } = state.config
	//const data = pages.find(page => page.path === props.location.pathname) 

	return {
        pages,
        colorSecondary,
        language
	}
}

const mapDispatchToProps = dispatch => ({
    setLanguage: (language) => dispatch(setLanguage(language)), 
})

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(App))
