import React, { useState, useRef, useEffect, useLayoutEffect, lazy, Suspense } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import moment from 'moment'

import { getEvents, setLoading } from 'actions'
import Detail from './Detail'
import styles from './Map.module.sass'

const Map = lazy(() => import('./Map'));

const monthNames = {
    de: [ 
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember'
    ],
    fr: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre'
    ]
}

const Index = ({ data, language, colorSecondary, getEvents, setLoading, eventsData, eventYears, location, history }) => {


    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    })

    const ref = useRef(null)
    const [selected, setSelected] = useState(false)
    const skipAnimation = useRef(false)

    const [events, setEvents] = useState({
        current: null,
        next: null,
        previous: null,
        events: [],
        test: [],
        leftMin: 10,
        leftMax: 90,
        topMin: 10,
        topMax: 90
    });

    /*
      leftMin: (Math.floor(Math.random() * 20) + 20),
        leftMax: (Math.floor(Math.random() * 20) + 40),
        topMin: (Math.floor(Math.random() * 20) + 20),
        topMax: (Math.floor(Math.random() * 20) + 40)
        */

    useEffect(() => {
        function handleResize() {

            skipAnimation.current = true

            setDimensions({
                width: ref.current.offsetWidth,
                height: ref.current.offsetHeight
            })
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        const d = new URLSearchParams(location.search).get('d')
        const year = d ? d.split('.')[1] : new Date().getFullYear()
        const loaded = eventYears.find(temp => temp.year === year)

        if(!loaded) {
            getEvents(language, [year, parseInt(year)+1])
            setLoading(true)
        }
    }, [language])

    useEffect(() => {

        if(events.current) {
            let year = parseInt(events.current.year) - 1
            let loaded = eventYears.find(temp => temp.year === year)

            if(!loaded) {
                getEvents(language, [year])
            }

            year = parseInt(events.current.year) + 1
            loaded = eventYears.find(temp => temp.year === year)

            if(!loaded) {
                getEvents(language, [year])
            }
        }

    }, [events.current])

    useEffect(() => {

        
        console.log(eventsData)

        let eventsData2 = [...eventsData]

        if(!eventsData.length) return 
        setLoading(false)

        console.log('>>>>>>>')
        

        //const urlParams = new URLSearchParams(location.search);

        const d = new URLSearchParams(location.search).get('d')
      
        const monthCurrent = d ? moment(`1.${d}`, 'D.M.YYYY').unix() : moment().unix();
        
        console.log(moment.unix(monthCurrent).format('DD-MM-YYYY'));

        const closestEvent = eventsData2.reduce((closest, event, i) => {
            //let timestampBest = 
            //console.log(moment(event.date, 'DD-MM-YYYY'), timestamp-monthCurrent)

            let diff = event.time - monthCurrent

            //console.log( moment(event.date, 'DD-MM-YYYY').format('DD-MM-YYYY'), diff )

            if (diff >= 0) {
                return !closest || diff < closest.time - monthCurrent ? event : closest
            }

            return closest

        }, null);

        if(!closestEvent) return

        console.log(closestEvent);
        //closestEvent.current = true;

        let monthBefore = moment(closestEvent.moment).add(-1, 'M').endOf('month').unix();
        let monthAfter = moment(closestEvent.moment).add(1, 'M').startOf('month').unix();

        console.log(moment(closestEvent.moment).add(-1, 'M').endOf('month').format('DD-MM-YYYY'));
        console.log(moment(closestEvent.moment).add(1, 'M').startOf('month').format('DD-MM-YYYY'));

        const previousEvent = eventsData2.reduce((closest, event, i) => {

            let diff = monthBefore - event.time

            if (diff >= 0) {
                return !closest || diff < monthBefore - closest.time  ? event : closest
            }
            
            return closest

        }, null);

        console.log(previousEvent);

        const nextEvent = eventsData2.reduce((closest, event, i) => {

            let diff = event.time - monthAfter

            if (diff >= 0) {
                return !closest || diff < closest.time - monthAfter  ? event : closest
            }

            return closest

        }, null);

        
        let months = [
            {
                type: 'current',
                event: closestEvent
            },
            {
                type: 'previous',
                event: previousEvent
            },
            {
                type: 'next',
                event: nextEvent
            }
        ]
        

      

        let test = {
            current: !closestEvent ? null : {
                month: moment(closestEvent.moment).format('M'),
                year: moment(closestEvent.moment).format('Y')
            },
            previous: !previousEvent ? null : {
                month: moment(previousEvent.moment).format('M'),
                year: moment(previousEvent.moment).format('Y')
            },
            next: !nextEvent ? null : {
                month: moment(nextEvent.moment).format('M'),
                year: moment(nextEvent.moment).format('Y')
            },
            events: [],
            test: [],
            leftMin: 0,
            leftMax: 100,
            topMin: 0,
            topMax: 100
        }


        months.forEach((month, i) => {
            console.log(month)

            if(month.event) {

                const startDate = moment(month.event.moment).startOf('month')
                const endDate = moment(month.event.moment).endOf('month')

                const eventsNew = eventsData2.filter(event2 => moment(event2.moment).isBetween(startDate, endDate, 'day', '[]' ) );
                
                let groupedEvents = []

                console.log(eventsNew)

                eventsNew.map(event => {

                    event = {...event}

                    let index = groupedEvents.findIndex(test2 => test2.top === event.top && test2.left === event.left);
                    
                    console.log(index)

                    if(month.type==='current') {
                        event.current = true
                    }

                    if(index<0) {
                        groupedEvents.push(event)
                        event.dates = [event.moment]
                    } else {
                        groupedEvents[index].dates.push(event.moment)

                        if(event.next) groupedEvents[index].next = true
                    }

                })

                //test.events = [...test.events, ...groupedEvents];
                test.events.push(groupedEvents);

                if(month.type==='current') {
                    //test.test = [...groupedEvents];

                    test.leftMin = groupedEvents.reduce((min, event) => event.left < min ? event.left : min, groupedEvents[0] ? groupedEvents[0].left : 0)
                    test.leftMax = groupedEvents.reduce((max, event) => event.left > max ? event.left : max, groupedEvents[0] ? groupedEvents[0].left : 100)
                    test.topMin = groupedEvents.reduce((min, event) => event.top < min ? event.top : min, groupedEvents[0] ? groupedEvents[0].top : 0)
                    test.topMax = groupedEvents.reduce((max, event) => event.top > max ? event.top : max, groupedEvents[0] ? groupedEvents[0].top : 100)

                }
            }
        });

        

        /*
        let startEvent = previousEvent ? previousEvent : closestEvent
        let endEvent = nextEvent ? nextEvent : closestEvent

        let startDate = moment(startEvent.date, 'DD-MM-YYYY').startOf('month')
        let endDate = moment(endEvent.date, 'DD-MM-YYYY').endOf('month')

        let startDateTemp = moment(closestEvent.date, 'DD-MM-YYYY').startOf('month')
        let endDateTemp = moment(closestEvent.date, 'DD-MM-YYYY').endOf('month')

        let eventsNew = eventsData2.filter(event => moment(event.date, 'DD-MM-YYYY').isBetween(startDate, endDate, 'day', '[]' ) );
        let eventsTemp = eventsData2.filter(event => moment(event.date, 'DD-MM-YYYY').isBetween(startDateTemp, endDateTemp, 'day', '[]' ) );
        */
        /* */

        console.log(test)

        console.log(test)

        setEvents(test)


    }, [eventsData, location])

    useLayoutEffect(() => {
        if (ref.current) {

            skipAnimation.current = true

            setDimensions({
                width: ref.current.offsetWidth,
                height: ref.current.offsetHeight
            });
        }
    }, []);

  
    let { leftMin, leftMax, topMin, topMax } = events
    
    console.log('leftMin', leftMin)
    console.log('leftMax', leftMax)
    console.log('topMin', topMin)
    console.log('topMax', topMax)

    /*
    if(selected) {
        leftMin = selected.left
        leftMax = selected.left
    }
    */


    const mapWidth = 1457
    const mapHeight = 1605

    let showWidth = (leftMax - leftMin) / 100 * mapWidth
    let showHeight = (topMax - topMin) / 100 * mapHeight

    //console.log(showWidth, showHeight)

    let avaiableWith = dimensions.width - 60

    let deviceScale = avaiableWith/mapWidth

    //if(avaiableWith>1130) avaiableWith = 1130

    const avaiableHeight = window.innerHeight - 220

    const scaleWidth = avaiableWith / showWidth
    const scaleHeight = avaiableHeight / showHeight

    let scale = scaleWidth < scaleHeight ? scaleWidth : scaleHeight

    //console.log(events.test.length)
    if(events.test.length===1 || scale === Infinity) scale=2
    //if(events.events.length===0) scale=0.05
    
    console.log(scale, deviceScale)
    
    if(scale/deviceScale>3) scale = 3*deviceScale

    //if(selected) scale = scale *1.5

    console.log(scale, deviceScale)
    

    const getDistance = ( x1, y1, x2, y2 ) => {
	
        var 	xs = x2 - x1,
            ys = y2 - y1;		
        
        xs *= xs;
        ys *= ys;
         
        return Math.sqrt( xs + ys );
    };

    let Testevents = []

   

    events.events.forEach(month => {

        //const cloned = JSON.parse(JSON.stringify(month))
        
        let month2 = month.map(event => (
            {
                ...event,
                children: [],
                parent: null,
                childNumber: 0,
                allDates: [...event.dates]
            }
        ))

        //console.log(JSON.stringify(month2))

        month2.forEach(event => {

            let diffClosest = null

            let month3 = month2.filter(compare => (compare.left != event.left || compare.top != event.top) && !compare.parent);
            //console.log('--------------------')
            //console.log(month3.length)

            event.test = 11

            
            const closestEvent = month3.reduce((closest, compare, i) => {

                let diffCompare = getDistance(event.left, event.top, compare.left, compare.top)

                if(!closest) {
                    diffClosest = diffCompare;
                    return compare
                }

                if(diffCompare < diffClosest) {
                    diffClosest = diffCompare;
                    return compare
                }
                
                return closest
    
            }, null);
            
            
            //console.log(closestEvent)

            

            if((diffClosest*scale)<5 && closestEvent && !event.children.length) {

                //console.log('GRROUUP')

                //console.log(JSON.stringify(event))
                //console.log(JSON.stringify(closestEvent))

                closestEvent.children.push(1)

                closestEvent.allDates = [...closestEvent.allDates, ...event.dates]

                

                //console.log('...')

                event.parent = {left: closestEvent.left, top: closestEvent.top}
                event.childNumber =  closestEvent.children.length

                //console.log(JSON.stringify(event))
                //console.log(JSON.stringify(closestEvent))

            }
            

            
            
        })

        Testevents.push(month2)
        //console.log(month)
        //return month2

    })

    //if(selected) scale /= 1.4

    //scale = 1

    console.log('scale:', `scale(${scale}%)`)

    console.log(scaleWidth, scaleHeight)

    let left = 0
    let top = 0

    left = -(showWidth * scale - avaiableWith) / 2 / scale
    top = -(showHeight * scale - avaiableHeight) / 2 / scale

    left -= leftMin / 100 * mapWidth
    top -= topMin / 100 * mapHeight
    /*
    if(selected) {
        let test = (window.innerWidth - dimensions.width)/scale;
        console.log('SELECTED')
        console.log(test)
    }
    */

    /*
    if(selected) { 
        scale = scale * 1.5

        console.log('SELECTED')

        showWidth = (0) / 100 * mapWidth
        showHeight = (0) / 100 * mapHeight
    
        //console.log(showWidth, showHeight)
        left = 0
        top = 0

        left = -(showWidth * scale - avaiableWith) / 2 / scale
        top = -(showHeight * scale - avaiableHeight) / 2 / scale

        left -= selected.left / 100 * mapWidth
        top -= selected.top / 100 * mapHeight
    }
    */

    /*
    if(selected) { 
        //scale*=1.5

        console.log('SELECTED')
        //console.log(leftMin, selected.left);

        console.log(selected.left/100*mapWidth)
        console.log(left)
        console.log(dimensions.width/2)

        //console.log(selected.left/100*mapWidth*scale)
        
        //let test =  (selected.left/100*mapWidth*scale)-left+(window.innerWidth/2)
        //let test = (((window.innerWidth)*scale/2)-((selected.left/100*mapWidth*scale) + left))-((window.innerWidth - dimensions.width)*scale)
        let test = (selected.left/100*mapWidth)+left
        
        console.log(test)
        
        test = ((dimensions.width/2)-test)

        console.log(test)

    
        //left+=test
        
        left = 

        //left-=360
    
    }
    */

    //const spots = []

    //console.log(Testevents)

    const spots = [].concat.apply([], Testevents).map((event, eventIndex) => {

        //let eventDate = moment(event.date, 'DD-MM-YYYY');

        let eventDates = event.allDates.sort((a, b) =>  moment(a).unix() - moment(b).unix());

        /*
        eventDates = eventDates.reduce((group, date) => {

            let current = moment(date, 'DD-MM-YYYY')

            if(!group.length) {
                group.push({
                    start: date,
                    end: date
                })
            } else {

                let last = group[group.length-1]

                if ( moment(current).add(-1, 'days').isSame( moment(last.end, 'DD-MM-YYYY')) || moment(current).isSame( moment(last.end, 'DD-MM-YYYY')) ) {
                    last.end = date
                } else {
                    group.push({
                        start: date,
                        end: date
                    })
                }

            }

            return group;

        }, [])
       
        eventDates = eventDates.map((group, index) => {
            
            if(group.start === group.end) {
                return  moment(group.start, 'DD-MM-YYYY').format('DD.MM.YY')
            } else {
                return  moment(group.start, 'DD-MM-YYYY').format('DD.MM.') + '&nbsp;&nbsp;&nbsp;–<br />' + moment(group.end, 'DD-MM-YYYY').format('DD.MM.YY')
            }

            
        })
        */

        let eventDates2

        if( moment(eventDates[0]).isSame( moment(eventDates[eventDates.length-1]), 'day' )) {
            eventDates2 = moment(eventDates[0]).format('DD.MM.YY')
        } else {
            eventDates2 = `${moment(eventDates[0]).format('DD.')}–${moment(eventDates[eventDates.length-1]).format('DD.MM.YY')}`
        }


        let classes = ['spot', 'bgSecondaryHover']
        event.next && classes.push('closest')
        event.children.length>0 && classes.push('group')
        event.current && classes.push('current')


        let key = event.id
        let left = event.left
        let top = event.top

        if(event.childNumber===1) {
            left  = event.parent.left + 1.6/scale
            top  = event.parent.top
        } else if(event.childNumber>1) {
            left  = event.parent.left + 0.8/scale
            top  = event.parent.top - 1.5/scale

        }

        let scaleTest = selected && selected.id === event.id ? scale/16 : scale
        scaleTest = scale
        
        return(
            <span className={classes.join(' ')} key={key}
                style={{
                    left: `${left}%`,
                    top: `${top}%`,
                    transform: `scale(${1 / scaleTest}) translate(-${scaleTest * 50}%, -${scaleTest * 50}%)`
                }}
                onClick={() => setSelected(event)}
            >
                {event.next &&
                    <span className="active" />
                }
                {(event.parent=='33333') && 
                    <span className="label" dangerouslySetInnerHTML={{__html: eventDates.join('<br />') }}></span>
                }
                {(!event.parent && dimensions.width>=600) &&
                    <span className="label" dangerouslySetInnerHTML={{__html: eventDates2 }}></span>
                }
            </span>
        )
    })


    console.log('skipAnimation: ' + skipAnimation.current)

    let mapClasses = ['map']
    //selected && mapClasses.push('test')

    if (skipAnimation.current) {
        mapClasses.push('noanimate')
        skipAnimation.current = false
    }

    const { current, previous, next } = events

    let monthName = !current 
                    ? '' 
                    : window.innerWidth>400
                    ? `${monthNames[language][current.month - 1]} ${current.year}`
                    : `${monthNames[language][current.month - 1].substring(0, 3)} ${current.year.substring(2, 4)}` 

    return (
        <div className={styles.map} ref={ref}>
            <div className="navigation">         
                <span 
                    onClick={() => { previous && history.push({...location, search: `?d=${previous.month}.${previous.year}`})}}
                    className={'previous' + (previous ? '' : ' disabled')}>&lt;
                </span>
                {monthName}
                <span
                    onClick={() => { next && history.push({...location, search: `?d=${next.month}.${next.year}`})}}
                    className={'next' + (next ? '' : ' disabled')}>&gt;
                </span>
            </div>
            <div className={mapClasses.join(' ')}>
                <div style={{ transform: `scale(${scale}) translate(${left}px, ${top}px)` }}>               
                    <Suspense fallback={<></>}>
                        <Map scale={scale} colorSecondary={colorSecondary} />
                    </Suspense>
                    {spots}
                </div>
            </div>
            <Detail
                active={!!selected}
                data={selected}
                onClose={() => setSelected(null)}
                colorSecondary={colorSecondary}
                language={language}
            />
        </div>
    );
};

const mapStateToProps = (state, props) => {
    const { language } = state.config
    const { events } = state.events
    console.log(events)

    return {
        language,
        eventsData: events[language].events,
        eventYears: events[language].years
    }
}

const mapDispatchToProps = dispatch => ({
    getEvents: (language, year) => dispatch(getEvents(language, year)), 
    setLoading: (loading) => dispatch(setLoading(loading)), 
})

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Index));

