import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { requestPassword } from 'actions'

import Input from 'components/page/parts/form/Input'

import styles from './Application.module.sass'
import styles2 from './ApplicationMain.module.sass'

import { withRouter, Link } from 'react-router-dom'

const RequestPassword = ({ authentication, requestPassword, language }) => {
    const [data, setData] = useState({
        email: ''
    });

    const onChange = (props) => {
        const newData = { ...data }
        newData[props.name] = props.value

        setData(newData)
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        console.log('submit')

        requestPassword(data.email, data.password)
    }

    let linkLanguage = language === 'fr' ? '/fr' : '';

    return (
        <div className={`${styles2.app} ${styles.application}`}>
            <h1>Passwort vergessen? <span className="fr">Mot de passe oublié ?</span></h1>
            {!authentication.isRequested
                ? <form>
                    <div>
                        <Input
                            labels={{ de: 'Email' }}
                            name="email"
                            value={data.email}
                            onChange={props => onChange(props)}
                        />
                    {authentication.error &&
                        <p className="error" style={{color: 'red', marginTop: '15px'}} dangerouslySetInnerHTML={{__html: authentication.error}}></p>
                    }
                    </div>
                    <div className="cols3" style={{marginTop: '25px'}}>
                        <div>
                            <span className="button submit" onClick={handleSubmit}>
                                Passwort anfordern<br />
                                <span className="fr">Demander un mot de passe</span>
                            </span>
                        </div>
                    </div>
                    <p style={{marginTop: '15px'}}>Schon registriert? <Link to={`${linkLanguage}/login/`}>Zum Login</Link> <span className="fr">Déjà inscrit ? <Link to={`${linkLanguage}/login/`}>Me connecter</Link></span></p>
                </form>
                : <div  style={{marginTop: '20px'}}>
                    Sie bekommen in Kürze eine E-Mail mit einem Bestätigungslink für Ihre Passwortänderung. <span className="fr">Un e-mail vient de vous être envoyé avec un lien permettant de choisir un nouveau mot de passe.</span>
                </div>
            }

        </div>
    )
}

const mapStateToProps = (state, props) => {
    const { authentication } = state

    return {
        authentication
    }
}

const mapDispatchToProps = dispatch => ({
    requestPassword: (email) => dispatch(requestPassword(email)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RequestPassword)