import React from 'react';
import { Link } from 'react-router-dom'
import styles from './Calendar.module.sass'
import moment from 'moment'

const Index = ({data, colorSecondary}) => {

    let events = data.sort((a, b) =>  moment(a.date, 'DD-MM-YYYY').unix() - moment(b.date, 'DD-MM-YYYY').unix());

    events = events.reduce((group, event) => {

        let current = moment(event.date, 'DD-MM-YYYY')

        if(!group.length) {
            group.push({
                ...event,
                start: current,
                end: current
            })
        } else {

            let last = group[group.length-1]

            //if ( moment(current).add(-1, 'days').isSame( moment(last.end, 'DD-MM-YYYY')) || moment(current).isSame( moment(last.end, 'DD-MM-YYYY')) ) {
            if( moment(current).isBetween( last.end, moment(last.end).add(1, 'days'), 'day', '[]' ) && last.location == event.location && last.place == event.place && last.title == event.title) {    
            
                last.end = current
            } else {
                group.push({
                    ...event,
                    start: current,
                    end: current
                })
            }

        }

        return group;

    }, [])

    console.log(events)


    return (
        <div className={styles.calendar}>
            <ul>
                {events.map((entry, i) => {

                    const isSameMonth = i===0 ? true : moment(entry.start).isSame( moment(events[i-1].start), 'month' ) ? true : false
                    
                    //const date = entry.start === entry.end ? entry.start : `${entry.start} - ${entry.end}`
                    let date = entry.start === entry.end ? moment(entry.start).format('DD.MM.YYYY') : `${moment(entry.start).format('DD.')}–${moment(entry.end).format('DD.MM.YYYY')}` 
                    
                    const text =  <>
                        <span style={{color: colorSecondary}}>{date}</span>
                        <span>
                            <span style={{backgroundImage: `linear-gradient(90deg,${colorSecondary},#001095)`}} dangerouslySetInnerHTML={{__html: `${entry.title} ${entry.place} ${entry.location}`}} />
                        </span>
                    </>

                    return ( 
                        <li className={!isSameMonth ? 'space' : ''}>
                           {entry.path ? (
                                <Link to={entry.path}>{text}</Link>
                            ) : (
                                <span>{text}</span>
                            )}
                        </li>
                    )
                })}
            </ul>
        </div>
    );
};

export default Index;