import React, { useRef, useEffect } from 'react';

const Checkbox = ({ labels, name, data, value, onChange, errors, style, className }) => {

    const component = useRef()

    useEffect(() => {

        component.current.addEventListener('click', anchorLinkListener);

        return () => {
            component.current.addEventListener('click', anchorLinkListener);
        }
    }, [])

    const handleClick = (entry) => {

        let valueNew = [...value]

        const index = valueNew.findIndex(row => row === entry.value)

        console.log(index)

        if (index > -1) {
            valueNew = valueNew.filter(row => row !== entry.value)
        } else {
            valueNew.push(entry.value)
        }

        onChange({
            name,
            value: valueNew
        })
    }

    const anchorLinkListener = (e) => {
        if (e.target.tagName === 'A') {
            e.stopPropagation()
        }
    }

    value = value ? value : []

    let classes = ['checkbox']
    if (errors && errors.findIndex(error => error.name === name) > -1) classes.push('error')
    if (className) classes.push(className)

    return (
        <div className={classes.join(' ')} style={style} ref={component}>
            {data.map((entry, i) => {

                const selected = value.findIndex(row => row === entry.value) > -1

                return <div className={className} onClick={() => handleClick(entry)} key={i}>
                    <span className="icon">
                        {selected &&
                            <span></span>
                        }
                    </span>
                    {entry.labels.all
                        ? <span className="label all" dangerouslySetInnerHTML={{ __html: entry.labels.all }}></span>
                        : <span className="label">{entry.labels.de} <span className="fr">{entry.labels.fr}</span></span>
                    }

                </div>
            })}
        </div>
    );
};

export default Checkbox;