import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
//import { authenticationActions } from 'actions'
import { login, validate } from 'actions'
import { withRouter, Link } from 'react-router-dom'

import Input from 'components/page/parts/form/Input'

import styles from './Application.module.sass'
import styles2 from './ApplicationMain.module.sass'

const Login = ({login, validate, authentication, history, location, language}) => {
    const [data, setData] = useState({
        email: '',
        password: ''
    });

    useEffect(() => {

        const query = new URLSearchParams(location.search)

		if(query.get('email') && query.get('key')) { 
            const email = decodeURIComponent(query.get('email'));
            const hash = decodeURIComponent(query.get('key'));

            validate(email, hash);
            //alert('ss')
		}



    }, [])

    //console.log(authentication)

    useEffect(() => {
        console.log(authentication)
		if(authentication.isLoggedin) {
            history.push('/application/')
        }
	}, [authentication])

    const onChange = (props) => {
        const newData = { ...data }
        newData[props.name] = props.value

        setData(newData)
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        console.log('submit')

        login(data.email, data.password)
    }

    let linkLanguage = language === 'fr' ? '/fr' : '';

    return (
        <div className={`${styles2.app} ${styles.application}`}>
            <h1>Login</h1>
            <form>
                <div>
                    <Input
                        labels={{ de: 'Email' }}
                        name="email"
                        value={data.email}
                        onChange={props => onChange(props)}
                    />
                    <Input
                        labels={{ de: 'Passwort', fr: 'Mot de passe' }}
                        name="password"
                        type="password"
                        value={data.password}
                        onChange={props => onChange(props)}
                    />
                    {authentication.validated &&
                        <p className="error" style={{color: 'green', marginTop: '15px'}}>Ihre E-Mail-Adresse wurde bestätigt. <span className="fr">Votre adresse e-mail a été confirmée.</span></p>
                    }
                    {authentication.error &&
                        <p className="error" style={{color: 'red', marginTop: '15px'}} dangerouslySetInnerHTML={{__html: authentication.error}}></p>
                    }
                    <div className="cols3" style={{marginTop: '25px'}}>
                        <div>
                            <span className="button submit" onClick={handleSubmit}>
                                Login<br />
                                <span className="fr"></span>
                            </span>
                        </div>
                    </div>
                    <p style={{marginTop: '15px'}}><Link to={`${linkLanguage}/request/`}>Passwort vergessen?</Link> <span className="fr"><Link to={`${linkLanguage}/request/`}>Mot de passe oublié ?</Link></span></p>
                    <p style={{marginTop: '15px'}}>Noch nicht registriert? <Link to={`${linkLanguage}/signup/`}>Zur Registrierung</Link> <span className="fr">Vous n'êtes pas encore enregistré ? <Link to={`${linkLanguage}/signup/`}>Me connecter</Link></span></p>
                </div>
            </form>
        </div>
    )
}

const mapStateToProps = (state, props) => {
    const { authentication } = state
   
    return {
        authentication
    }
}

const mapDispatchToProps = dispatch => ({
    login: (email, password) => dispatch(login(email, password)),
    validate: (email, hash) => dispatch(validate(email, hash)),
})

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Login))