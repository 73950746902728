import React from 'react';

const Radio = ({ labels, name, data, value, onChange, errors, className }) => {

    const handleClick = (entry) => {
        onChange({
            name,
            value: entry.value
        })
    }

    let classes = ['radio']
    if (errors && errors.findIndex(error => error.name === name) > -1) classes.push('error')
    if (className) classes.push(className)

    return (
        <div className={classes.join(' ')}>
            {data.map((entry, i) => {

                const selected = entry.value === value

                return <div className={className} onClick={() => handleClick(entry)} key={i}>
                    <span className="icon">
                        {selected &&
                            <span></span>
                        }
                    </span>
                    <span className="label">{entry.labels.de} <span className="fr">{entry.labels.fr}</span></span>
                </div>
            })}
        </div>
    );
};

export default Radio;