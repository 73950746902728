import { put, takeEvery, delay, takeLatest, all, fork, call, select } from 'redux-saga/effects'
import { eventConstants } from '../constants';
import { fetchPage } from '../api';

export function* handleEventsLoad(payload) {
    console.log('>>>')
    console.log(payload)
    
    try {
        //yield console.log('ss')
        //const page = yield select(getPage);
        const events = yield call(fetchPage, `/?events&language=${payload.language}&year=${payload.years.join(',')}`);
        //console.log(events)
        //page.path = payload.path
        //yield put(setImages(images));
        yield put({ type: eventConstants.GETEVENTS_SUCCESS, events, language: payload.language })
    } catch (error) {
        yield console.log('ERROR')
        //yield put(setError(error.toString()));
    }
}

export default function* watchEventLoad() {
    yield takeEvery(eventConstants.GETEVENTS_REQUEST, handleEventsLoad);
}