import qs from "qs"
import axios from 'axios'

//const URL = `http://localhost/milchhof/impulsneuemusik/deploy/api`;
const URL = `https://api.impulsneuemusik.com`;


const fetchPage = async path => {
    console.log(path)
    const response = await fetch(`${URL}${path}`);
    const data = await response.json();
    if (response.status >= 400) {
        throw new Error(data.errors);
    }
    return data;
};

const fetchData = async authParams =>  {
    return await axios.request({
      method: 'post',
      url: 'https://backend.impulsneuemusik.com/loader.php',
      data: qs.stringify(authParams)
    });
}

export { fetchPage, fetchData };