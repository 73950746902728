import React from 'react';
import { Link } from 'react-router-dom'
import styles from './Teaserlist.module.sass'

const Index = ({data, colorSecondary}) => {

    let style = data.withColor ? {backgroundColor: colorSecondary} : {}
        
    return (
        <div className={styles.teaserlist + (data.withColor ? ' colored' : '')}>
            <div className="flex">
                {data.items.map((entry, i) => {
                    
                    let styleImage = {...style}
                    if(entry.image) styleImage.backgroundImage = `url(${entry.image.path})`
                    
                    return (
                        <div key={i}>
                            <div className="borderColorSecondaryHover">
                                <Link to={entry.path} className="area" />
                                <div className="image" style={styleImage}></div>
                                <h3>{entry.title}</h3>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default Index;