import { put, takeEvery, delay, takeLatest, all, fork, call, select } from 'redux-saga/effects'

import { authenticationConstants } from '../constants';
import { fetchData } from '../api';

import { validateEmail } from 'helper'

const getLanguage = state => state.config.language;

export function* handleSignup(payload) {
    try {

        const language = yield select(getLanguage);

        if (!validateEmail(payload.email) || payload.password.length < 6) {
            yield put({ type: authenticationConstants.SIGNUP_FAILURE, error: 'Bitte eine gültige E-Mail-Adresse und ein Passwort mit mindestens 6 Zeichen angeben. <span class="fr">Indiquer une adresse e-mail valide et un mot de passe avec minimum 6 caractères.</span>' })
        } else {

            let payloadNew = {
                action: 'signup',
                email: payload.email,
                password: payload.password,
                language
            }

            let { data } = yield call(fetchData, payloadNew);

            if (data.success) {
                yield put({ type: authenticationConstants.SIGNUP_SUCCESS })

            } else {
                yield put({ type: authenticationConstants.SIGNUP_FAILURE, error: data.error })
            }
        }

    } catch (error) {

    }
}

export function* handleValidate(payload) {
    try {

        let payloadNew = {
            action: 'validate',
            email: payload.email,
            hash: payload.hash
        }

        let { data } = yield call(fetchData, payloadNew);

        if (data.success) {
            yield put({ type: authenticationConstants.VALIDATE_SUCCESS, user: data.success })

        } else {
            yield put({ type: authenticationConstants.VALIDATE_FAILURE, error: data.error })
        }

    } catch (error) {

    }

}

export function* handleLogin(payload) {
    console.log(payload)
    try {

        let payloadNew = {
            action: 'login',
            email: payload.email,
            password: payload.password
        }

        let { data } = yield call(fetchData, payloadNew);

        if (data.success) {
            localStorage.setItem('user2023', JSON.stringify({ email: payload.email, token: data.success.token }));
            yield put({ type: authenticationConstants.LOGIN_SUCCESS, email: payload.email, token: data.success.token })

        } else {
            yield put({ type: authenticationConstants.LOGIN_FAILURE, error: data.error })
        }

    } catch (error) {

    }
}

export function* handleLogout(payload) {
    try {
        localStorage.removeItem('user2023');
        yield put({ type: authenticationConstants.LOGOUT_SUCCESS })
    } catch (error) {

    }
}

export function* handleRequest(payload) {
    const language = yield select(getLanguage);

    if (!validateEmail(payload.email)) {
        yield put({ type: authenticationConstants.REQUESTPASSWORD_FAILURE, error: 'Ein Fehler ist aufgetreten. <span class="fr">Une erreur est survenue.</span>' })
    } else {

        let payloadNew = {
            action: 'requestPassword',
            email: payload.email,
            language
        }

        let { data } = yield call(fetchData, payloadNew);

        if (data.success) {
            yield put({ type: authenticationConstants.REQUESTPASSWORD_SUCCESS })

        } else {
            yield put({ type: authenticationConstants.REQUESTPASSWORD_FAILURE, error: data.error })
        }
    }
}

export function* handleChange(payload) {
    const language = yield select(getLanguage);

    if (payload.password.length < 6) {
        yield put({ type: authenticationConstants.CHANGEPASSWORD_FAILURE, error: 'Bitte Passwort mit mindestens 6 Zeichen angeben. <span class="fr">Veuillez saisir un mot de passe avec au moins 6 caractères.</span>' })
    } else {

        let payloadNew = {
            action: 'changePassword',
            email: payload.email,
            password: payload.password,
            language,
            hash: payload.hash
        }

        let { data } = yield call(fetchData, payloadNew);

        if (data.success) {
            yield put({ type: authenticationConstants.CHANGEPASSWORD_SUCCESS })

        } else {
            yield put({ type: authenticationConstants.CHANGEPASSWORD_FAILURE, error: data.error })
        }
    }
}

export default function* watchPageLoad() {
    yield takeEvery(authenticationConstants.SIGNUP_REQUEST, handleSignup);
    yield takeEvery(authenticationConstants.VALIDATE_REQUEST, handleValidate);
    yield takeEvery(authenticationConstants.LOGIN_REQUEST, handleLogin);
    yield takeEvery(authenticationConstants.LOGOUT_REQUEST, handleLogout);
    yield takeEvery(authenticationConstants.REQUESTPASSWORD_REQUEST, handleRequest);
    yield takeEvery(authenticationConstants.CHANGEPASSWORD_REQUEST, handleChange);
}