import { configConstants } from '../constants';

const initialState = {
    navigation: {
        'de': {
            main: [],
            meta: []
        },
        'fr': {
            main: [],
            meta: []
        }
    },
    language: window.location.pathname.split('/')[1] === 'fr' ? 'fr' : 'de'
}

export function config(state = initialState, action) {
    switch (action.type) {
        case configConstants.GETCONFIG_REQUEST:
            return {
                ...state
            }
        case configConstants.GETCONFIG_SUCCESS:

            return {
                ...state,
                navigation: action.data.navigation,
                colorSecondary: action.data.colorSecondary
            }
        case configConstants.GETCONFIG_FAILURE:
            return {
                error: action.error
            }

        case configConstants.SETLANGUAGE_SUCCESS:

            return {
                ...state,
                language: action.language
            }

        default:
            return state
    }
}