import React, { useEffect, useRef } from 'react';

const Input = ({labels, name, type, value, onChange, onBlur, addText, addClasses, errors, placeHolder, smallLabel, readonly}) => {

    const handleChange = (event) => {
        onChange && onChange({
            name, 
            value: event.target.value 
        })
    }

    const handleBlur = (event) => {

        console.log(type)

        if(type === 'currency') {
            let value = event.target.value 

            console.log('Ss')
    
            if (isNaN(value) || !value) {
                value = ''
            } else {
                //value = parseInt(value)
                value = parseFloat(value)
                value = value.toFixed(2);
            }

            onChange && onChange({
                name, 
                value
            })
            

        }

        console.log(event.target.value)


        onBlur && onBlur()
    }

    const initialChange = useRef()

    
    useEffect(() => {
        if(type === 'currency' && !initialChange.current && value) {
            let initialValue = value 

            console.log('Ss')
    
            if (isNaN(value) || !value) {
                value = ''
            } else {
                //value = parseInt(value)
                value = parseFloat(value)
                value = value.toFixed(2);
            }

            if(value !== initialValue) {
                onChange && onChange({
                    name, 
                    value
                })
            }
        }

        initialChange.current = true

    }, [type, value])
    

    if(type === 'currency') {
        //value = parseFloat(value)
        //value = value.toFixed(2);  
    }

    const inputType = type === 'currency' ? 'number' : type ? type : 'text'

   

    let classes = ['input']
    if(addClasses) classes = classes.concat(addClasses)
    if(errors && errors.findIndex(error => error.name === name)>-1) classes.push('error')

    return (
        <div className={classes.join(' ')}>
            {labels &&
                <label className={smallLabel ? 'small' : ''}>{labels.de} <span className="fr">{labels.fr}</span></label>
            }
           <input type={inputType} value={value} onChange={handleChange} onBlur={handleBlur} placeHolder={placeHolder} readonly={readonly} />
           
           
            {addText &&
                <span>{addText}</span>
            }
        </div>
    );
};

export default Input;