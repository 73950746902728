import React from 'react';

const Logo = () => {
    return (
        <svg width="130" height="114.8" viewBox="0 0 130 114.8">
            <path d="M76.77,102.4c0,4.53-2.54,8.22-7.06,8.22s-7.07-3.1-7.07-8.22V90H58.2v0l0,0L47.38,109.5,37,90H32.61v24.8H37V99.15l8.38,15.65h3.72L58.2,98.37v4c0,7.91,4.65,12.4,11.51,12.4s11.5-5.55,11.5-12.4V90H76.77Z" />
            <polygon points="130 90 124.73 90 115 101.04 115 90 110.56 90 110.56 114.8 115 114.8 115 103.73 124.42 114.8 130 114.8 118.96 102 130 90" />
            <path d="M87,97.16c0-1.7,1.58-3,4.1-3a15.37,15.37,0,0,1,7.22,1.89l.8-4a15.7,15.7,0,0,0-7.75-2c-4.8,0-8.64,2.54-8.64,7.25,0,8.68,12.67,5.83,12.67,10.45,0,1.74-1.3,3-4.43,3a17,17,0,0,1-7.69-1.83l-.55,4.09a20.22,20.22,0,0,0,8.12,1.86c5.86,0,9-3.44,9-7.47C99.81,98.84,87,101.81,87,97.16Z" />
            <rect width="4.43" height="24.8" />
            <path d="M12.62,30.09v24.8h4.43v-9H22.6a7.83,7.83,0,0,0,8-8c0-4.28-2.82-7.82-8-7.82ZM26,38c0,2.08-1.55,3.91-4.43,3.91h-4.5v-7.6h4.5C24.4,34.31,26,36.05,26,38Z" />
            <path d="M49.51,84.8V80.58H37V74.51h9.49V70.29H37V64.22H51.18V72.4c0,7.91,4.65,12.4,11.5,12.4s11.5-5.55,11.5-12.4V60H69.75V72.4c0,4.53-2.54,8.22-7.07,8.22s-7.07-3.1-7.07-8.22V60h-23V78L16.53,60H12.62V84.8h4.43V67.44L32.61,84.8Z" />
            <polygon points="74.09 50.68 62.96 50.68 62.96 30.09 58.53 30.09 58.53 54.9 74.09 54.9 74.09 50.68" />
            <polygon points="93.65 64.22 93.65 60 76.75 60 76.75 84.8 93.65 84.8 93.65 80.58 81.19 80.58 81.19 74.51 90.67 74.51 90.67 70.29 81.19 70.29 81.19 64.22 93.65 64.22" />
            <rect x="102.37" y="90" width="4.43" height="24.8" />
            <path d="M12.62,9.15,20.43,24.8h3.72l8.5-16.43,0,34.12c0,7.91,4.65,12.4,11.5,12.4s11.5-5.54,11.5-12.4V30.09H51.18v12.4c0,4.53-2.54,8.22-7.07,8.22S37,47.61,37,42.49L37.08,0H32.65V0l0,0L22.39,19.53,12.62,0H8.19V24.8h4.43Z" />
            <path d="M87.92,47.8c0,1.73-1.31,3-4.44,3A17,17,0,0,1,75.86,49v4.34a20.26,20.26,0,0,0,7.5,1.58c5.86,0,9-3.44,9-7.47,0-8.49-12.81-5.51-12.81-10.16,0-1.71,1.59-3,4.1-3a15.49,15.49,0,0,1,7.22,1.89l.81-4a15.76,15.76,0,0,0-7.75-2.05c-4.81,0-8.65,2.55-8.65,7.26C75.24,46,87.92,43.18,87.92,47.8Z" />
        </svg>
    );
};

export default Logo;