import React, { useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import styles from './Projectslist.module.sass'

import AudioPlayer from 'components/page/parts/audioplayer'

import moment from 'moment'
import { useState } from 'react';

const Image = ({data, colorSecondary}) => {
    if(!data) return null

    const className = data.width > data.height ? 'landscape' : 'portrait'
    const backgroundImage = `url(${data.path}), linear-gradient(${colorSecondary}, #0022bf)`

    return (
        <div className={`image ${className}`}>
            {data.caption && 
                <p>{data.caption}</p>
            }
            <div style={{
                paddingBottom: `${data.height/data.width*100}%`,
                backgroundImage: backgroundImage
            }} />
        </div>
    )
}

const Project = withRouter(({data, language, colorSecondary, history}) => {
    const [ allEvents, setAllEvents ] = useState(false)

    let events = data.events.sort((a, b) =>  moment(a.date, 'DD-MM-YYYY').unix() - moment(b.date, 'DD-MM-YYYY').unix());

    events = events.reduce((group, event) => {

        let current = moment(event.date, 'DD-MM-YYYY')

        if(!group.length) {
            group.push({
                ...event,
                start: current,
                end: current
            })
        } else {

            let last = group[group.length-1]

            if( moment(current).isBetween( last.end, moment(last.end).add(1, 'days'), 'day', '[]' ) && last.location == event.location && last.place == event.place) {    
            
                last.end = current
            } else {
                group.push({
                    ...event,
                    start: current,
                    end: current
                })
            }

        }

        return group;

    }, [])

    console.log(data.title)
    console.log(events)

    return (
        <li id={data.name} key={data.name}>
            <h3 style={{color: colorSecondary}} dangerouslySetInnerHTML={{__html: data.title}} />
            <div>
                <div>
                    <div>
                        <div className="number" style={{borderColor: colorSecondary, color: colorSecondary}}>
                            <div>
                                <span><span>{data.projectNumber}</span></span>
                            </div>
                        </div>
                        {(data.composers.length>0) && 
                            <div className="composers" style={{color: colorSecondary}}>
                                {data.composers.map((entry, i) => (
                                    <strong>{entry}</strong>
                                ))}
                            </div>
                        }
                        {(events.length>0) && 
                            <div className={'events' + (!allEvents ? ' less' : '')}>
                                <ul>
                                    {events.map((entry, i) => {
                                        
                                        let date = entry.start === entry.end ? moment(entry.start).format('DD.MM.YYYY') : `${moment(entry.start).format('DD')}–${moment(entry.end).format('DD.MM.YYYY')}` 
                                    
                                        let target = {
                                            pathname: language === 'de' ? '/' : '/' + language,
                                            search: `?d=${moment(entry.start).format('M.Y')}`
                                        }

                                        return (
                                            <li key={i}>
                                                <Link 
                                                    to={target}
                                                    style={{backgroundImage: `linear-gradient(90deg,${colorSecondary},#001095)`}}
                                                    >{date}<br />{entry.location}<br/>{entry.place}</Link>
                                            </li>
                                        )
                                    })}
                                </ul>
                                {(events.length>7 && !allEvents) &&
                                    <span className="colorSecondaryHover" onClick={() => setAllEvents(!allEvents) }>{language === 'de' ? 'weitere Termine' : 'd\'autres dates'}</span>
                                }
                            </div>
                        }
                        <div className="music">
                        
                            
                            {data.music.map((entry, i) => (
                                    <AudioPlayer 
                                    key={i}
                                    data={entry}
                                    playingID={null}
                                    onPlay={(id) => {}} colorSecondary={colorSecondary} />
                            ))}
                            
                        
                        </div>
                    </div>
                </div>
                <div>
                    <Image
                        data={data.image}
                        colorSecondary={colorSecondary} />
                    <div
                        className="description rich"
                        dangerouslySetInnerHTML={{__html: data.description}} />
                </div>
            </div>
        </li>
    )
})

const Index = withRouter(({data, colorSecondary, language}) => {

    return (
        <div className={styles.projectslist}>
            <ul>
                {data.map((entry, i) => (
                    <Project
                        data={entry}
                        language={language}
                        colorSecondary={colorSecondary} />
                ))}
            </ul>
        </div>
    );
});

export default Index;