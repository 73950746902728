import { applicationConstants } from '../constants';

const initialState = {application: null, isPosting: null}

export function application(state = initialState, action) {
	switch (action.type) {
        case applicationConstants.GET_APPLICATION_REQUEST:
            return {
                ...state
            }
        case applicationConstants.GET_APPLICATION_SUCCESS:

            return {
                ...state,
                application: action.application
            }
        case applicationConstants.GET_APPLICATION_FAILURE:
            return { 
                error: action.error
            }
        
        case applicationConstants.POST_APPLICATION_REQUEST:
            return {
                ...state,
                isPosting: true
            }
        
        case applicationConstants.POST_APPLICATION_SUCCESS:
            return {
                ...state,
                application: action.application,
                isPosting: false
            }

        case applicationConstants.POST_APPLICATION_FAILURE:
            return {
                ...state,
                isPosting: false
            }
            
        default:
            return state
    }
}
    