import React from 'react';
import styles from './Text.module.sass'

const Index = ({data}) => {
    return (
        <div className={styles.text + ' rich cols' + data.cols.length }>
            {data.cols.map((col, i)=> (
                <div key={i} dangerouslySetInnerHTML={{__html: col}}></div>
            ))}
        </div>
    );
};

export default Index;